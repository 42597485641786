:root {
    --hue: 100deg;
    --dark-color: hsl(var(--hue), 100%, 9%);
    --light-color: hsl(var(--hue), 95%, 98%);
    --base: hsl(var(--hue), 95%, 50%);
    --complimentary1: hsl(var(--hue-complimentary1), 95%, 50%);
    --complimentary2: hsl(var(--hue-complimentary2), 95%, 50%);
  
    --font-family: "Poppins", system-ui;
  
    --bg-gradient: linear-gradient(
      to bottom,
      var(--layout-background-color), var(--layout-background-color)
    );
  }
  
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    background: var(--bg-gradient);
}

.orb-canvas {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--layout-background-overlay);
  }
  
  canvas {
    background-color: #f0f0f0; /* Set your desired background color here */
  }

  /* Not too many browser support this yet but it's good to add! */
  /* @media (prefers-contrast: high) {
    .orb-canvas {
      display: none;
    }
  } */
  