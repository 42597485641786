/*  Mirrors Edge Color Pallet */
/* https://www.color-hex.com/color-palette/101183 */

/*
RED #fd1111	(253,17,17)
ORANGE #ff5c04	(255,92,4)
YELLOW #fbe816	(251,232,22)
GREEN #4bf624	(75,246,36)
BLUE #0d34c1	(13,52,193)
*/
@import url('https://fonts.googleapis.com/css2?family=Megrim&display=swap');

html,
body {
    color: var(--text-color);
    margin-left: auto;
    margin-right: auto;
    margin: 0px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 0px;
    
    word-wrap: anywhere;
}

* {
    box-sizing: border-box;
}

a {
    color: var(--text-color);
    text-decoration: none;
}

a:hover {
    text-decoration: none;
    cursor: pointer;
}

img {
    max-width: 100%;
    display: block;
}

/*******************************************************************************************************/
/* FIX CHROME STYLES
/*******************************************************************************************************/

/*******************************************************************************************************/
/* FIX CHROME STYLES
/*******************************************************************************************************/
textarea, input, button{
    outline: none;
    border: none;
    -webkit-appearance: none;
}

input:disabled {
    background: #333333;
    cursor: not-allowed;
}

select {
    padding: 10px;
    margin: 10px;
    color: var(--text-color);

    border-color: var(--text-color);
    border-radius: 25px;
    background: transparent;
    box-shadow: var(--default-box-shadow);
}

select option{
    padding: 10px;
    margin: 10px;
    color: var(--text-color-invert);

    border-radius: 25px;
    box-shadow: var(--default-box-shadow);
}

  
/*******************************************************************************************************/
/* SCROLLBAR STYLES
/*******************************************************************************************************/

::-webkit-scrollbar-track {
}

::-webkit-scrollbar {
  width: 10px;
  height: 11px;
  background-color: var(--background-color-3); /* or add it to the track */

  border-radius: 10px;
  bottom: -10px;
}

::-webkit-scrollbar-thumb {
    background-color: var(--background-color-1); /* or add it to the track */
    border-radius: 25px;
}

::-webkit-scrollbar-track {
    height: 8px;
    background-color: var(--background-color-3); /* or add it to the track */

}

/*******************************************************************************************************/
/* HEADING STYLES
/*******************************************************************************************************/
.heading2Xl {
    font-size: 2.5rem;
    line-height: 1.2;
    font-weight: 800;
    letter-spacing: -0.05rem;
    margin: 1rem 0;
}

.headingXl {
    font-size: 2rem;
    line-height: 1.3;
    font-weight: 800;
    letter-spacing: -0.05rem;
    margin: 1rem 0;
}

.headingLg {
    font-size: 1.5rem;
    line-height: 1.4;
    margin: 1rem 0;
}

.headingSmall {
    font-size: 1.0rem;
    line-height: 1.4;
    margin: 1rem 0;
}

.headingMd {
    font-size: 1.2rem;
    line-height: 1.5;
}

/*******************************************************************************************************/
/* GENERAL STYLES
/*******************************************************************************************************/
.introPanelHeading {
    padding: 10px;
    border-radius: 10px;
    background: var(--background-color-1-AA);
    box-shadow: var(--default-box-shadow);
    overflow: auto;

    margin-bottom: 10px;
    position: relative;
}

.introPanelContent {
    padding: 10px;
    border-radius: 10px;
    background: var(--background-color-1-AA);
    box-shadow: var(--default-box-shadow);
    overflow: auto;

    margin-bottom: 30px;
    position: relative;
}

.spacer1rem{
    height: 1rem;
}

.margin20px {
    margin: 20px;
}

.spacer10px {
    height: 10px;
}

.spacer20px {
    height: 20px;
}

.grid {
    display: grid;
    grid-auto-flow: column;
    height: 100%;
    padding-bottom: 10px;
    gap: 10px;
}

.gridArea {
    display: grid;
    place-content: center;
    place-items: center;
}

@media (max-width: 900px) {
    .grid {
        grid-auto-flow: row;
        grid-template-columns: 1fr 1fr;
    }
}

.download-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
    border-radius: 5px;
    border: 0.1px solid #fff;
    padding: 10px;
    color: white;
    font-size: 16px;
    text-decoration: none;
    cursor: pointer;
  }
  
  .download-button img {
    height: 24px;
    margin-right: 10px;
  }
  
  .download-text {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .download-text1 {
    text-align: left;
    font-size: 10px;
    line-height: 12.5px;
  }
  
  .download-text2 {
    text-align: left;
    font-weight: bold;
    line-height: 12.5px;
  }
  
  .inline {
    display: flex;
    align-items: center;
  }
/*******************************************************************************************************/
/* INTROJS STYLES
/*******************************************************************************************************/
.introTooltip {
    background: var(--background-color-1);
    border-color: var(--background-color-1);
    box-shadow: var(--default-box-shadow);
    border-radius: 25px;
}

.introjs-arrow.left {
    border-right-color: var(--background-color-1);
}

.introjs-button {
    color: var(--text-color);
    font-size: 1.0em !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
    border-radius: 25px !important;
    border: 1px solid var(--toggle-button-color) !important;
    background: transparent !important;
    text-shadow: none !important;
}

.introjs-button:hover {
    color: var(--text-color-invert) !important;
    text-align: left !important;
    background: var(--default-white-color) !important;
    background-color: var(--toggle-button-color) !important;
}

.introjs-button a:hover{
    color: var(--text-color-invert) !important;
    text-align: left !important;
    background: var(--default-white-color) !important;
    background-color: var(--toggle-button-color) !important;
}

.introjs-button:focus {
    color: var(--text-color) !important;
}

.introjs-button:focus:hover {
    color: var(--text-color-invert) !important;
}

.introjs-button:active {
    color: var(--text-color) !important;
}

.introHighlight{
    border: 2px solid var(--default-white-color) !important;
}

/*******************************************************************************************************/
/* GENERAL STYLES
/*******************************************************************************************************/
textarea, input {
    color: var(--text-color);
    font-size: 15px;

    padding: 10px;
    margin: 10px;

    border-radius: 25px;
    border: 1px solid var(--input-border-color);
    background: transparent;
}

input[type="checkbox"] {
    color: var(--background-color-1);
    font-size: 15px;
    border-radius: 25px;
    outline: 2px solid var(--default-red-color);
    width: 10px !important;
    box-shadow: none !important;
    border: 2px solid transparent;
    vertical-align: middle;
}

input[type="checkbox"]:checked {
    color: var(--background-color-1);
    font-size: 15px;
    border-radius: 25px;
    border: 2px solid var(--background-color-1);
    width: 8px !important;
    height: 8px !important;
    box-shadow: none !important;
    background-color: var(--default-red-color);
    outline: 2px solid var(--default-red-color);
    vertical-align: middle;
}

input:hover {
}

textarea, input::placeholder {
    color: var(--placeholder-color);
}

svg {
    color: var(--svg-color);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
    font-size: 1.0em;
    padding: 10px;
    margin: 10px;
    border-radius: 25px;
}

button:disabled {
    background-color: rgba(239, 239, 239, 0.3) !important;
    color: rgba(16, 16, 16, 0.3) !important;
    border-color: rgba(118, 118, 118, 0.3) !important;
}

button:disabled:hover {
    box-shadow: none;
}


ul {
    padding-inline-start: 0px;
}

table {
    border-collapse: collapse;
    width: 100%;
    margin: 20px;
    font-size: 20px;
}

th {
    text-align: left;
}

tr {
    width: 100%;
    border-bottom: 1px solid #363636;
}

tr:last-child {
    border-bottom: unset;
}

.panel {
    padding: 10px;
    border-radius: 10px;
    background: var(--background-color-1-AA);
    box-shadow: var(--default-box-shadow);
    overflow: auto;
    backdrop-filter: blur(50px);
    position: relative;
}

.panel:hover {
    box-shadow: var(--default-box-shadow-hover);
}

.headingPanelWithButton{
    padding: 10px;
    border-radius: 10px;
    background: var(--background-color-1-AA);
    box-shadow: var(--default-box-shadow);
    overflow: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.headingPanelWithButton:hover {
    box-shadow: var(--default-box-shadow-hover);
}

.panelRed {
    padding: 10px;
    border-radius: 10px;
    background: var(--coloured-gradient);
    box-shadow: var(--default-box-shadow);
    position: relative;
}

.panelRed:hover {
    box-shadow: var(--default-box-shadow-hover);
}

.panelTransparent {
    padding: 10px;
    border-radius: 10px;
    background: var(--background-color-1-AA);
    box-shadow: var(--default-box-shadow);
    position: relative;
}

.panelTransparent:hover {
    box-shadow: var(--default-box-shadow-hover);
}

.buttonColored {
    color: var(--button-coloured-text-color);
    text-align: center;
    background: var(--coloured-gradient);
    box-shadow: var(--default-box-shadow);
}

.buttonColored svg {
    color: var(--button-coloured-text-color);
    margin-right: 5px;
    margin-left: 5px;
    box-shadow: var(--default-box-shadow);
}

.buttonColored:hover {
    color: var(--button-coloured-text-color);
    border-radius: 25px;
    box-shadow: var(--default-box-shadow-hover);
}

.buttonRed {
    color: var(--default-red-color);
    font-size: 1.0em;
    padding: 10px;
    border-radius: 25px;
    border: 1px solid var(--default-red-color) !important;
    text-align: left;
    background: transparent;
    box-shadow: var(--default-box-shadow);
}

.buttonRed > svg {
    color: var(--default-red-color);
}

.buttonRed:hover {
    color: var(--text-color-invert);
    border-radius: 25px;
    border: 1px solid var(--default-red-color);
    background: var(--default-red-color);
    box-shadow: var(--default-box-shadow-hover);
}

.buttonRed:hover > svg{
    color: var(--text-color-invert);
}

.buttonBlue {
    color: var(--default-blue-color);
    font-size: 1.0em;
    padding: 10px;
    border-radius: 25px;
    border: 1px solid var(--default-blue-color);
    text-align: left;
    background: transparent;
    box-shadow: var(--default-box-shadow);;
}

.buttonBlue > svg {
    color: var(--default-blue-color);
}

.buttonBlue:hover > svg{
    color: var(--text-color-invert);
}

.buttonBlue:hover {
    color: var(--text-color-invert);
    border-radius: 25px;
    border: 1px solid var(--default-blue-color);
    background: var(--default-blue-color);
    box-shadow: var(--default-box-shadow-hover);
}

.buttonGreen {
    color: var(--default-green-color);
    font-size: 1.0em;
    padding: 10px;
    border-radius: 25px;
    border: 1px solid var(--default-green-color);
    text-align: left;
    background: transparent;
    box-shadow: var(--default-box-shadow);
}

.buttonGreen > svg {
    color: var(--default-green-color);
}

.buttonGreen:hover {
    color: var(--text-color-invert);
    border-radius: 25px;
    border: 1px solid var(--default-green-color);
    background: var(--default-green-color);
    box-shadow: var(--default-box-shadow-hover);
}

.buttonGreen:hover > svg{
    color: var(--text-color-invert);
}

.buttonSave {
    color: var(--text-color);
    border-radius: 25px;
    border: 1px solid var(--default-white-color);
    background: transparent;
    box-shadow: var(--default-box-shadow-hover);
}

.buttonSave:hover {
    color: var(--text-color-invert);
    border-radius: 25px;
    border: 1px solid var(--default-white-color);
    background: var(--default-white-color);
    box-shadow: var(--default-box-shadow-hover);
}

.buttonSave:hover svg {
    color: var(--text-color-invert);
    border-radius: 25px;
    border: 1px solid var(--default-white-color);
    background: var(--default-white-color);
    box-shadow: var(--default-box-shadow-hover);
}

.statusDotRed {
  background-color: var(--default-red-color);
  height: 15px;
  width: 15px;
  border-radius: 50%;
}

.statusDotYellow {
  background-color: var(--default-yellow-color);
  height: 15px;
  width: 15px;
  border-radius: 50%;
}

.statusDotGreen {
  background-color: var(--default-green-color);
  height: 15px;
  width: 15px;
  border-radius: 50%;
}

.statusDotBlack {
  background-color: var(--default-black-color);
  height: 15px;
  width: 15px;
  border-radius: 50%;
}

.infoPopup{
    box-shadow: var(--default-box-shadow);
    padding: 10px;
    line-break: normal;
    word-wrap: break-word;
}

.codePanel{
	padding: 10px;
	background-color: var(--default-black-color);
	color: var(--default-white-color);

	margin: 12.5px;
	padding: 10px;
	border-radius: 25px;
    box-shadow: var(--default-box-shadow);
    position: relative;
}

.readOrHide {
	color: var(default-red-color);
	vertical-align: middle !important;
	cursor: pointer;
	margin-top: 20px;
	display: block;
	font-size: 20px;
}

.floatRight{
    float: right;
}


/*******************************************************************************************************/
/* LOGIN STYLES
/*******************************************************************************************************/
.loaderContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
        "area1 area1 area1"
        "area1 area1 area1"
        "area1 area1 area1";
    max-width: 1200px;
}

.loginInput {
    border-color: var(--login-input-border-color);
    color: var(--login-input-border-color) !important;
}

.loginInput::placeholder {
    color: var(--login-input-border-color) !important;
}

.loaderArea1 {
    grid-area: area1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/** STYLES FOR MeteorRainLoading **/
.jJGE {
    height: 500px;
    width: 500px;
}

.kxiECb {
    background: transparent linear-gradient(90deg, var(--gradient-color-2) 0%, var(--gradient-color-1) 100%) 0% 0% no-repeat padding-box
}

/** STYLES FOR TransverseLoading **/
.bXMTkv {

}

.IYFxP {
    background-color: var(--gradient-color-1);
    height: 25px;
    width: 25px;
    margin-right: 25px;
}

.jdirUU {
    background-color: var(--gradient-color-15);
    height: 25px;
    width: 25px;
    margin-right: 25px;

}

.lbdEYM {
    background-color: var(--gradient-color-2);
    height: 25px;
    width: 25px;
}

/*******************************************************************************************************/
/* LOGIN STYLES
/*******************************************************************************************************/
.loginContainer {
  background-image: var(--login-background-image);
  background-position: center;
  background-size: cover;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "area1 area2 area3"
    "area1 area4 area5"
    "area1 area6 area7";
  height: 100vh;
}

@media (max-width: 900px) {
    .loginContainer {
        background-image: var(--login-background-image);
        background-position: center;
        background-size: cover;

        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        gap: 0px 0px;
        grid-auto-flow: row;
        grid-template-areas:
            "area2 area3 area4"
            "area5 area1 area6"
            "area6 area6 area6";
        height: 100vh;
    }
}

.loginArea1 {
    grid-area: area1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    backdrop-filter: blur(20px);
    border-radius: 0px;
}

.loginArea2 { grid-area: area2; }

.loginArea3 { grid-area: area3; }

.loginArea4 { grid-area: area4; }

.loginArea5 { grid-area: area5; }

.loginArea1 form {
    display: grid;
}

.loginArea1 input {
    background: transparent;
    width: 250px !important;
    color: var(--text-color-invert);
}

.loginArea1 button {
    text-align: center;
    margin-top: 10px;
}

.loginArea6 { grid-area: area6; }

.loginArea7 { grid-area: area7; }

.loginArea8 { grid-area: area8; }

.loginArea9 { grid-area: area9; }

.logoLogin {
    height: 250px;
    width: 250px;
    background-image: var(--login-logo-image);
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: contain; /* Resize the background image to cover the entire container */
}

/*******************************************************************************************************/
/* SIDEBAR STYLES
/*******************************************************************************************************/
.sidebarHeaderHeight {
    height: 80px;
    margin: none;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.pro-sidebar > .pro-sidebar-inner {
    background: unset !important;
}

.pro-sidebar-header {
    border: none !important;
}

.pro-sidebar-content {
    border: none !important;

    color: var(--text-color);
}

@media (max-width: 900px) {
    .pro-sidebar-header {
        background-color: var(--background-color-1);
        z-index: 200;
    }
    .pro-sidebar-content {
        background-color: var(--background-color-1);
        z-index: 200;
    }
    .pro-sidebar-footer {
        background-color: var(--background-color-1);
        z-index: 200;
    }
}

.pro-inner-list-item {
    background-color: var(--background-color-3-AA) !important;
}

.pro-sidebar-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border: none !important;
    padding: 10px;

}

/* MENU ITEM HOVER TEXT COLOR */
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
    color: unset !important;
}

.pro-menu-item {
    color: var(--text-color);
}
.pro-menu-item.sidebarActive {
    background: transparent linear-gradient(90deg, var(--gradient-color-1) 0%, var(--gradient-color-2) 100%) 0% 0% no-repeat padding-box !important;
    color: var(--text-color-invert);
}

.pro-menu-item.sidebarActive > div > span > span > svg{
    color: var(--text-color-invert) !important;
}

.sidebarActive .pro-icon-wrapper {
    background: transparent !important;
}

.pro-icon-wrapper {
    background: var(--background-color-1) !important;
}

/*******************************************************************************************************/
/* LAYOUT STYLES
/*******************************************************************************************************/
/* ----------------------------------- */
.layoutLangSelect {
    padding: 5px;
    border-radius: 25px;
    border: 1px solid var(--input-border-color) !important;
    color: var(--text-color);
    background: transparent;
}

.layoutLangSelect option{
    margin: 10px;
    border-radius: 25px;
    color: var(--text-color-invert);
    box-shadow: var(--default-box-shadow);
}

.profileDropdown {
    position: relative;
    display: inline-block;
}

.logoLayout {
    height: 90%;
    width: 100%;
    padding: 15px;
    background-image: var(--logo-layout-image);
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: contain; /* Resize the background image to cover the entire container */
}

.profileDropdownContent {
    display: none;
    position: absolute;

    z-index: 1;
    right: 0px;

    width: max-content;
}

.profileDropdown:hover {
    color: var(--text-color);
}

.profileDropdown:hover .profileDropdownContent {
    color: var(--text-color);
    display: inherit;
}

.notificationDropdown {
    position: relative;
    display: inline-block;
    margin: 10px;
}

/* .sysgridNotificationDropdown>button {
  display: inline-block;
  border-radius: 60px;
  border: 1px solid #fff;
  height: 29px !important;
  width: 29px !important;
  padding: 5px !important;
} */

.notificationDropdown svg:hover {
    color: var(--svg-color);

    cursor: pointer;
}

.notificationDropdownContentContainer {
    display: grid;
    position: absolute;
    width: max-content;
    right: 0px;
    z-index: 1000 !important;

    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
        "area1"
        "area2";
}

.notificationDropdownContentArea1 {
    grid-area: area1;

    font-size: 13px;
    color: var(--text-color);
}

.notificationDropdownContentArea2 {
    grid-area: area2;
}

.notificationDropdownContentContainer li {
    border-bottom: 1px solid var(--text-color);
    padding: 10px;
    list-style: none;
}

.notificationDropdownContentContainer li:last-child {
    border-bottom: none;
}

/* ----------------------------------- */
.pro-sidebar .pro-menu {
    padding: unset;
}

.headingLayout {
    font-family: 'Megrim';
    font-weight: 100;
    font-size: 3.0rem;
    line-height: 1.4;
    margin: 0px;
    text-align: center;
}

.layoutSearch {
    background: var(--coloured-gradient);
    width: calc(100% - 100px);
    margin-right: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    color: var(--text-color-search);
    border: none; 
}

.layoutSearch::placeholder {
    color: var(--text-color-search);
}


.layoutSearchButton {
    margin-left: -2px;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    background: var(--coloured-gradient);
    font-size: 15px;
    color: var(--text-color-search);
}

.layoutSearchButton svg {
    color: var(--text-color-search) !important;
}

.layoutSearchButton:hover {
    color: var(--text-color-invert);
}

.buttonBars {
    background: transparent;
    color: var(--text-color);
    font-size: 30px;
}

.layoutContainer {
    display: grid;
    grid-template-columns: max-content max-content 0.5fr 0.5fr;
    grid-template-rows: 80px calc(100vh - 80px);
    gap: 0px 0px;
    grid-template-areas:
      "area1 area2 area3 area4"
      "area1 area5 area5 area5";
    overflow: hidden;
    overflow-y: scroll;
    z-index: 1000;
}

.layoutContainer::-webkit-scrollbar {
    display: none;
}

.layoutArea1 {
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -o-sticky;
    position: -ms-sticky;
    position: sticky;
    top: 0px;

    grid-area: area1;

    height: 100vh;

    display: flex;
    flex-direction: column;
    background-color: var(--background-color-1-AA);
    align-items: center;
    justify-content: center;
    z-index: 100;
}

@media (max-width: 900px) {
    .layoutArea1 {
        z-index: 200;
    }
}

.layoutArea1mobile {
    position: sticky;
    top: 0px;

    display: none;
}

.layoutArea2 {
    position: sticky;
    top: 0px;

    grid-area: area2;

    background-color: var(--background-color-1);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.layoutArea3 {
    position: sticky;
    top: 0px;

    grid-area: area3;

    padding-left: 20px;
    background-color: var(--background-color-1-AA);
    display: inline;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    z-index: 100;
}

.layoutArea3 form {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

@media (max-width: 900px) {
    .layoutArea3 {
        z-index: 100;
        background: var(--background-color-1);
    }
}

.layoutArea4 {
    position: sticky;
    top: 0px;

    grid-area: area4;

    display: flex;
    flex-direction: row;
    background-color: var(--background-color-1-AA);
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

@media (max-width: 900px) {
    .layoutArea4 {
        z-index: 100;
        background: var(--background-color-1);
    }
}

.layoutArea5 {
    grid-area: area5;
    padding: 50px;

    min-width: 100% !important;
    max-width: 1700px;

    display: fixed;
}

.layoutArea5Area1 {
    padding-bottom: 50px;
}

@media (max-width: 900px) {
    .layoutContainer {
        display: grid;
        grid-template-columns: max-content max-content 0.5fr;
        grid-template-rows: 80px calc(100vh - 80px);
        gap: 0px 0px;
        grid-template-areas:
          "area1 area2 area3 area4"
          "area5 area5 area5 area5";
    }

    .layoutArea1mobile {
        grid-area: area1;

        height: 80px;

        display: flex;
        flex-direction: column;
        background-color: var(--background-color-1);
        align-items: center;
        justify-content: center;

        z-index: 100;
    }

    .layoutArea3 {
        padding-left: unset;
        z-index: 100;
    }

    .layoutArea5 {
        grid-area: area5;

        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;

        height: 100%;
        width: 100%;

        min-width: unset !important;
        max-width: unset;
    }

    .hideOnMobile {
        display: none !important;
    }

    .layoutSearch {
        width: calc(100% - 20px);
    }
}

.hide {
    display: none !important;
}

/*******************************************************************************************************/
/* SEARCH STYLES
/*******************************************************************************************************/
.searchContainer {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    grid-template-areas:
        "area1"
        "area2"
        "area3"
        "area4"
        "area5"
        "area6"
        "area7";
}

.searchArea1 {
    grid-area: area1;
}

.searchArea2 {
    grid-area: area2;
    margin-top: 40px;
 }

.searchArea3 {
    grid-area: area3;
 }

.searchArea4 {
    grid-area: area4;
    margin-top: 40px;
 }

.searchArea5 {
    grid-area: area5;
}

.searchArea6 {
    grid-area: area6;
    margin-top: 40px;
}

.searchArea7 {
    grid-area: area7;
}

/*******************************************************************************************************/
/* HOME STYLES
/*******************************************************************************************************/
.homeContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 15px 15px;
    grid-template-areas:
        "area1 area2 area3 area4" 
        "area5 . . ."
}

@media (max-width: 900px) {
    .homeContainer {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        gap: 15px 15px;
        grid-template-areas:
            "area1"
            "area2"
            "area3"
            "area4"
            "area5";
    }
}

.homeArea1 {
    grid-area: area1;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: max-content max-content max-content;
    gap: 0px 0px;
    grid-template-areas:
      "area1 . ."
      ". area2 ."
      ". . .";
}

.homeArea1Area1 {
    grid-area: area1;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.homeArea1Area2 {
    grid-area: area2;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.homeArea2 {
    grid-area: area2;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: max-content max-content max-content;
    gap: 0px 0px;
    grid-template-areas:
      "area1 . ."
      ". area2 ."
      ". . .";
 }

.homeArea2Area1 {
    grid-area: area1;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.homeArea2Area2 {
    grid-area: area2;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.homeArea3 {
    grid-area: area3;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: max-content max-content max-content;
    gap: 0px 0px;
    grid-template-areas:
    "area1 . ."
    ". area2 ."
    ". . .";
 }

.homeArea3Area1 {
    grid-area: area1;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.homeArea3Area2 {
    grid-area: area2;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.homeArea4 {
    grid-area: area4;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: max-content max-content max-content;
    gap: 0px 0px;
    grid-template-areas:
      "area1 . ."
      ". area2 ."
      ". . .";
 }

.homeArea4Area1 {
    grid-area: area1;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.homeArea4Area2 {
    grid-area: area2;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.homeArea5 {
    grid-area: area5;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: max-content max-content max-content;
    gap: 0px 0px;
    grid-template-areas:
      "area1 . ."
      ". area2 ."
      ". . .";
}

.homeArea5Area1 {
    grid-area: area1;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.homeArea5Area2 {
    grid-area: area2;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


/*******************************************************************************************************/
/* HOME STYLES
/*******************************************************************************************************/
.homeNewsContainer {
  display: grid;
  grid-template-columns: 1fr ;
  gap: 15px 15px;
  grid-template-areas:
    "area1"
    "area2 ";
    margin-top: 20px;
}

.homeNewsOverwriteMargin {
    margin-top: -8px !important;
}

.homeNewsArea1 {
    grid-area: area1;
}

.homeNewsArea2 {
    grid-area: area2;
 }

.homeNewsArea2Panel {
    margin-top: 10px;
    position: relative;
}

.homeNewsArea2Panel li{
    list-style-type: disc;
    margin-left: 20px;
}

.homeNewsArea2Meta {
    font-size: 13px;
}

/*******************************************************************************************************/
/* CONTAINERS STYLES (MEHRZAHL)
/*******************************************************************************************************/
.containersContainer {
    display: grid;
    width: 100%;
    grid-template-columns: .1fr 1fr .2fr 1fr;
    gap: 10px 10px;
    grid-template-areas:
        "area1 area2 area3 area4";
    grid-area: containersWrapperArea;

    background-color: var(--background-color-1);
    margin-top: 10px;
}

.containersArea1 {
    grid-area: area1;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.containersArea2 {
    grid-area: area2;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flexShrink: 1;
}

.containersArea3 {
    grid-area: area3;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.containersArea4 {
    grid-area: area4;

    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

/*******************************************************************************************************/
/* CONTAINER STYLES (EINZAHL)
/*******************************************************************************************************/
/* IMPORTANT TO VERTICALLY CENTER THE LOGO IMAGE */
.containerPanelVerticalAlignHelper {
    display: block;
    height: 100%;
    width: 100%;
    vertical-align: middle;
}

.containerLogo {
    max-width: 35px;
    max-height: 35px;
    margin-right: 10px;
    vertical-align: middle;
    float: left;
}

.containerContainer {
    margin-top: 10px;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: masonry;
    gap: 10px 10px;
    grid-template-areas:
        "area1 area2"
        "area3 area3"
        "area4 area5"
        "area6 area6"
}

@media (max-width: 900px) {
  .containerContainer {
	  grid-template-areas:
        "area1 area1"
		"area2 area2"
        "area3 area3"
        "area4 area4"
        "area5 area5"
        "area6 area6";
    }
}
.containerArea1 {
    grid-area: area1;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.containerArea2 {
    grid-area: area2;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.containerArea3 {
	grid-area: area3;
    word-wrap: break-word;
    word-break: break-all;
}

.containerArea4 {
	grid-area: area4;
}

.containerArea5 {
	grid-area: area5;
}

.containerArea6 {
	grid-area: area6;
}

.containerArea7 {
	grid-area: area7;
    word-wrap: break-word;
    word-break: break-all;
}

/*******************************************************************************************************/
/* TOGGLE BUTTON STYLES
/*******************************************************************************************************/
.toggleButton button[class^="Button_button"] {
    margin-top: 10px;
    color: var(--text-color);
    font-size: 1.0em;
    padding: 10px;
    border-radius: 25px;
    border: 1px solid var(--toggle-button-color) !important;
    text-align: left;
    background: transparent;
    box-shadow: var(--default-box-shadow);
    z-index: 100;
}

.toggleButton button[class^="Button_button"] span {
    z-index: 100 !important;
}

.toggleButton button[class^="Button_button"]:hover {
    color: var(--text-color-invert);
    font-size: 1.0em;
    padding: 10px;
    border-radius: 25px;
    border: 1px solid var(--toggle-button-color) !important;
    text-align: left;
    background: transparent;
    box-shadow: var(--default-box-shadow);
    background-color: var(--toggle-button-color);
}

.toggleButton button[class^="Button_button"]:hover svg {
    color: var(--text-color-invert);
}

/**************/

.toggleButtonSmall button[class^="Button_button"] {
    color: var(--text-color);
    font-size: 1.0em;
    padding: 5px;
    border-radius: 25px;
    border: 1px solid var(--toggle-button-color) !important;
    text-align: left;
    background: transparent;
    box-shadow: var(--default-box-shadow);
    z-index: 100;
}

.toggleButtonSmall button[class^="Button_button"] span {
    z-index: 100 !important;
}

.toggleButtonSmall button[class^="Button_button"]:hover {
    color: var(--text-color-invert);
    font-size: 1.0em;
    padding: 5px;
    border-radius: 25px;
    border: 1px solid var(--toggle-button-color) !important;
    text-align: left;
    background-color: var(--toggle-button-color);
    box-shadow: var(--default-box-shadow-hover);
}

.toggleButtonSmall button[class^="Button_button"]:hover svg {
    color: var(--text-color-invert);
}

/**************/

.toggleButtonText button[class^="Button_button"] {
    font-size: 1.0em;
    padding: 5px;
    text-align: left;
    z-index: 100;

    border-style: none;
    background-color: transparent;
    color: var(--text-color);
}

.toggleButtonText button[class^="Button_button"] span {
    z-index: 100 !important;
}

/* UNDERLINE ONLY THE BUTTON WORD "STATUS" */
/* .toggleButtonText button[class^="Button_button"] > span > span:nth-child(2) {
    z-index: 100 !important;
    border-bottom: 1px solid var(--text-color);
} */

.toggleButtonText button[class^="Button_button"]:hover {
    font-size: 1.0em;
    padding: 5px;

    text-align: left;
}

.toggleButtonText button[class^="Button_button"] svg {
    
}
/*******************************************************************************************************/
/* MODAL BUTTON STYLES
/*******************************************************************************************************/
.modalButtonBlack {
    margin-top: 10px;
    color: var(--default-black-color);
    font-size: 1.0em;
    padding: 10px;
    border-radius: 25px;
    border: 1px solid var(--default-black-color) !important;
    text-align: left;
    background: transparent;
    box-shadow: var(--default-box-shadow);;
    z-index: 100;
    width: calc(100% - 20px);
    text-align: center;
}
  
.modalButtonBlack:hover {
    color: var(--background-color-2);
    font-size: 1.0em;
    padding: 10px;
    border-radius: 25px;
    border: 1px solid var(--default-white-color) !important;
    background: transparent;
    box-shadow: var(--default-box-shadow);
    background-color: var(--default-black-color);
}


/*******************************************************************************************************/
/* MODAL STYLES
/*******************************************************************************************************/
.skylight-dialog {
    border-radius: 10px !important;
}

/*******************************************************************************************************/
/* TEMPLATES STYLES
/*******************************************************************************************************/
.templateLogo {
    width: 50px;
    height: 50px;
    vertical-align: middle;
    border-radius: 22.5%;
}

.templatesForm {
    top: 0px !important;
    position: absolute;
    margin: 25px;
}

.templatesForm input {
    max-width: 250px;
}

.templatesContainer {
    font-size: 1.2rem;
    column-gap: 1rem;
    margin-top: 1rem;
    columns-fill: balance;
    display: flex;
    flex-wrap: wrap;
    row-gap: 1rem;
}

.templatesArea1 {
    display: flex;
    flex-direction: column;
    width: calc(25% - 0.75rem);
    break-inside: avoid;
    text-align: center;

    justify-content: space-between;
}

.buttonInstall {
    align-self: center; /* align the button to the bottom of the div */
    margin-top: auto;
}

@media (max-width: 900px) {
    .templatesArea1 {
        width: 100%;
    }
}

.badge {
    border-radius: 25px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 15px;
    width: max-content;
}

.templatesArea1Area1 {
    grid-area: area1;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 10px;
}

.templatesArea1Area2 {
    grid-area: area2;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.templatesArea1Area3 {
    grid-area: area3;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.templatesArea1Area3 h3 {
    margin: 10px;
}


.templatesArea1Area4 {
    grid-area: area4;
    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 10px;
}

.templatesArea1Area4 p {
    margin: 0px;
    font-size: 15px;
    white-space: pre-wrap;
}

.templatesFormSubdomain {
    display: inline-block;
    vertical-align: left;
    width: calc(50% - 25px) !important;
}

.templatesFormDomain {
    vertical-align: right;
}


/*******************************************************************************************************/
/* PROJECTS STYLES
/*******************************************************************************************************/
.projectsAppStatusTable {
    width: max-content;
}

.projectsAppStatus {
    width: max-content;
    display: inline;
    font-size: 15px;
}

.projectsDomainsStatusTable {
    width: max-content;
    margin: 5px;
}

.projectsDomainsStatus {
    width: max-content;
    font-size: 15px;
}

.projectsContainer {
    font-size: 20px;
    column-gap: 1rem;
    margin-top: 1rem;
    columns-fill: balance;
    display: flex;
    flex-wrap: wrap;
    row-gap: 1rem;
}

.projectsContainerlistTable {
    width: max-content;
    font-size: 15px;
    margin: 5px;
}

.projectsContainerlistContainer {
    width: max-content;
}

.statusDotTd {
    width: 50px;
}

.projectsArea1 {
    flex-direction: column;
    justify-content: space-between;
    width: calc(25% - 0.75rem);
    break-inside: avoid;
    text-align: center;
    overflow-y: hidden;
}


@media (max-width: 1200px) {
    .projectsContainer {
        font-size: 20px;
        column-gap: 1rem;
        margin-top: 1rem;
        columns-fill: balance;
        display: grid;
        grid-template-columns: calc(33% - 1rem/2) calc(33% - 1rem/2) calc(33% - 1rem/2);
        flex-wrap: wrap;
        row-gap: 1rem;
    }

    .projectsArea1 {
        width: 100%;
    }
}

@media (max-width: 900px) {
    .projectsContainer {
        font-size: 20px;
        column-gap: 1rem;
        margin-top: 1rem;
        columns-fill: balance;
        display: grid;
        grid-template-columns: calc(50% - 1rem/2) calc(50% - 1rem/2);
        flex-wrap: wrap;
        row-gap: 1rem;
    }

    .projectsArea1 {
        width: 100%;
    }
}
  
.projectsArea1Area1 {
    grid-area: area1;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
  
.projectsArea1Area2 {
    grid-area: area2;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
  
.projectsArea1Area3 {
    grid-area: area3;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    margin-bottom: 10px;
}
  
.projectsArea1Area4 {
    grid-area: area4;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
  
.projectsArea1Area5 {
    grid-area: area5;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
  
.projectsArea1Area5CheckboxArea {
    display: flex;

    align-items: center;
    justify-content: center;
    vertical-align: middle;
}


/*******************************************************************************************************/
/* PROJECTS COMPACT STYLES
/*******************************************************************************************************/
.projectsCompactContainer {
    font-size: 20px;
    column-gap: 1rem;
    margin-top: 1rem;
    columns-fill: balance;
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    flex-wrap: wrap;
    row-gap: 1rem;
}

.projectsCompactContainerlistTable {
    width: max-content;
}

.projectsCompactContainerlistContainer {
    width: max-content;
}

.statusDotTd {
    width: max-content;
    padding: 5px;
}

.projectsCompactArea1 {
    flex-direction: column;
    justify-content: space-between;
    break-inside: avoid;
    text-align: center;

    flex-basis: calc(20% - 5*1rem/2);
}

@media (max-width: 1200px) {
    .projectsCompactContainer {
        font-size: 20px;
        column-gap: 1rem;
        margin-top: 1rem;
        columns-fill: balance;
        display: grid;
        grid-template-columns: calc(33% - 1rem/2) calc(33% - 1rem/2) calc(33% - 1rem/2);
        flex-wrap: wrap;
        row-gap: 1rem;
    }

    .projectsCompactArea1 {
        width: 100%;
    }
}

@media (max-width: 900px) {
    .projectsCompactContainer {
        font-size: 20px;
        column-gap: 1rem;
        margin-top: 1rem;
        columns-fill: balance;
        display: grid;
        grid-template-columns: calc(50% - 1rem/2) calc(50% - 1rem/2);
        flex-wrap: wrap;
        row-gap: 1rem;
    }

    .projectsCompactArea1 {
        width: 100%;
    }
}
  
.projectsCompactArea1Area1 {
    grid-area: area1;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
  
.projectsCompactArea1Area2 {
    grid-area: area2;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.projectsCompactArea1Area2 h3 {
    margin: 0px;
}

.projectsCompactArea1Area2 span {
    font-size: 15px;
}

.projectsCompactArea1Area3 {
    grid-area: area3;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    margin-bottom: 10px;
}
  
.projectsCompactArea1Area4 {
    grid-area: area4;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
  
.projectsCompactArea1Area5 {
    grid-area: area5;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
  
.projectsCompactArea1Area5CheckboxArea {
    display: flex;

    align-items: center;
    justify-content: center;
    vertical-align: middle;
}

/*******************************************************************************************************/
/* USER STYLES
/*******************************************************************************************************/
.editUserForm {
    display: contents;
}

.createUserForm {
    display: contents;
}

.userContainer {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    gap: 10px 10px;
    grid-template-areas:
        "area1"
        "area2"
        "area3"
        "area4";
    grid-area: containersWrapperArea;
    margin-top: 10px;
}

.userArea1 {
    grid-area: area1;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.userArea2 {
    grid-area: area2;

    margin-top: 20px;

    display: inline;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.userArea3 {
    grid-area: area3;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.userArea4 {
    grid-area: area4;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.userArea2 input {
    width: unset;
}

.userArea4 input {
    width: unset;
}

.qrCodeContainer {
    background-color: var(--default-white-color);
    width: max-content;
    height: max-content;
    padding: 10px;
}

/*******************************************************************************************************/
/* DATAFOLDER STYLES
/*******************************************************************************************************/
.dataFolderLogo {
    width: 25px;
    height: 25px;
    vertical-align: middle;
    border-radius: 22.5%;
}

.dataFolderContainer {
    display: grid;
    width: 100%;
    grid-template-columns: .2fr 1fr 2fr 1fr;
    gap: 10px 10px;
    grid-template-areas:
        "area1 area2 area3 area4";
    grid-area: containersWrapperArea;

    margin-top: 10px;
}

@media (max-width: 900px) {
    .dataFolderContainer {
        display: grid;
        width: 100%;
        grid-template-columns: .2fr 1fr 2fr 1fr;
        gap: 10px 10px;
        grid-template-areas:
        "area1 area1 area2 area2"
        "area3 area3 area3 area4";
        grid-area: containersWrapperArea;
    
        margin-top: 10px;
    }
}

.dataFoldersArea1 {
    grid-area: area1;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10px;
}

.dataFoldersArea2 {
    grid-area: area2;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10px;
}


.dataFoldersArea3 {
    grid-area: area3;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flexShrink: 1;
}

.dataFoldersArea3 table {
    font-size: unset !important;
    width: max-content;
}

.dataFoldersArea3 table td {
    padding-right: 10px;
}

.dataFoldersArea4 {
    grid-area: area4;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}


/*******************************************************************************************************/
/* ADMIN BACKUPS STYLES
/*******************************************************************************************************/
.backupsLogo {
    width: 25px;
    height: 25px;
    vertical-align: middle;
    border-radius: 22.5%;
}

.backupsForm {
    display: block;
    max-width: 250px !important;
}

.backupsFormHidden {
    display: none;
}

.backupsContainer {
    display: grid;
    width: 100%;
    grid-template-columns: .2fr 1fr 2fr;
    gap: 10px 10px;
    grid-template-areas:
        "area1 area2 area3"
        "area4 area4 area4";
    grid-area: containersWrapperArea;
    margin-top: 10px;
}

.backupsArea1 {
    grid-area: area1;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10px;
}

.backupsArea2 {
    grid-area: area2;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10px;
}


.backupsArea3 {
    grid-area: area3;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flexShrink: 1;
}

.backupsArea3 table {
    font-size: unset !important;
    width: max-content;
}

.backupsArea3 table td {
    padding-right: 10px;
}

.backupsArea4 {
    grid-area: area4;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    padding: 10px;
}



/*******************************************************************************************************/
/* ADMIN BACKUPS STYLES
/*******************************************************************************************************/
.backupsLogo {
    width: 25px;
    height: 25px;
    vertical-align: middle;
    border-radius: 22.5%;
}

.backupsForm {
    display: block;
    max-width: 250px !important;
}

.backupsFormHidden {
    display: none;
}

.backupsContainer {
    display: grid;
    width: 100%;
    grid-template-columns: .2fr 1fr 2fr;
    gap: 10px 10px;
    grid-template-areas:
        "area1 area2 area3"
        "area4 area4 area4";
    grid-area: containersWrapperArea;
    margin-top: 10px;
}

.backupsArea1 {
    grid-area: area1;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10px;
}

.backupsArea2 {
    grid-area: area2;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10px;
}


.backupsArea3 {
    grid-area: area3;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flexShrink: 1;
}

.backupsArea3 table {
    font-size: unset !important;
    width: max-content;
}

.backupsArea3 table td {
    padding-right: 10px;
}

.backupsArea4 {
    grid-area: area4;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    padding: 10px;
}


/*******************************************************************************************************/
/* ADMIN VPN STYLES
/*******************************************************************************************************/
.vpnLogo {
    width: 25px;
    height: 25px;
    vertical-align: middle;
    border-radius: 22.5%;
}

.vpnForm {
    display: block;
    max-width: 250px !important;
    
}

.vpnFormHidden {
    display: none;
}

.vpnContainer {
    display: grid;
    width: 100%;
    grid-template-columns: 2fr 1fr 2fr;
    gap: 10px 10px;
    grid-template-areas:
        "area1 area2 area3"
        "area4 area4 area4";
    grid-area: containersWrapperArea;
    margin-top: 10px;
}

.vpnArea1 {
    grid-area: area1;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10px;
}

.vpnArea2 {
    grid-area: area2;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10px;
}


.vpnArea3 {
    grid-area: area3;

    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    flexShrink: 1;
}

.vpnArea3 table {
    font-size: unset !important;
    width: max-content;
}

.vpnArea3 table td {
    padding-right: 10px;
}

.vpnArea4 {
    grid-area: area4;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    padding: 10px;
}

.vpnArea4Inner{
    display: grid;
    grid-template-columns: 1fr 5fr;
    align-items: flex-start;
    justify-content: flex-start;

    padding: 10px;
}

.vpnArea4QrCode{
    margin: 0px;
    padding: 10px;
}

.vpnArea4Certinfo h3{
    margin: 0px;
}

.vpnArea4Certinfo{
    margin: 0px;
    padding: 10px;
    font-size: 15px;
}


/*******************************************************************************************************/
/* SECURITY AIDE STYLES
/*******************************************************************************************************/
.securityAideContainer {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 10px 10px;
    grid-template-areas:
        "area1 area2 area3 area4 area5";
    grid-area: containersWrapperArea;
    margin-top: 10px;
}

.securityAideArea1 {
    grid-area: area1;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10px;
}

.securityAideArea2 {
    grid-area: area2;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10px;
}

.securityAideArea3 {
    grid-area: area3;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10px;
}

.securityAideArea4 {
    grid-area: area4;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10px;
}

.securityAideArea5 {
    grid-area: area5;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10px;
}

/*******************************************************************************************************/
/* SECURITY AIDE LOG STYLES
/*******************************************************************************************************/
.securityAideLogsContainer {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 10px 10px;
    grid-template-areas:
        "area1 area2 area3 area4" 
        "area5 area5 area5 area5" 
        "area6 area6 area6 area6";
    grid-area: containersWrapperArea;
    margin-top: 10px;
}

.securityAideLogsArea1 {
    grid-area: area1;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 10px;
}

.securityAideLogsArea2 {
    grid-area: area2;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 10px;
}


.securityAideLogsArea3 {
    grid-area: area3;
    
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 10px;
}

.securityAideLogsArea4 {
    grid-area: area4;
    
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 10px;
}

.securityAideLogsArea4Status {
    display: flex;
    align-items: center;
    margin-left: 10px; /* Adjust margin as needed */
}

.securityAideLogsArea5 {
    grid-area: area5;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10px;
}

.securityAideLogsArea6 {
    grid-area: area6;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10px;
}


/*******************************************************************************************************/
/* SECURITY IPBLACKLIST STYLES
/*******************************************************************************************************/
.securityIpBlacklistContainer {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    gap: 10px 10px;
    grid-template-areas:
        "area1"
        "area2";
    grid-area: containersWrapperArea;
    margin-top: 10px;
}

.securityIpBlacklistArea1 {
    grid-area: area1;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10px;
}

.securityIpBlacklistArea2 {
    grid-area: area2;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10px;
}

.securityIpBlacklistArea3 {
    grid-area: area3;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10px;
}

.securityIpBlacklistArea4 {
    grid-area: area4;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10px;
}

.securityIpBlacklistArea5 {
    grid-area: area5;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10px;
}

/*******************************************************************************************************/
/* ADMIN USER STYLES
/*******************************************************************************************************/
.adminUserContainer {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    gap: 10px 10px;
    grid-template-areas:
        "area1"
        "area2"
        "area3"
        "area4"
        "area5";
    grid-area: containersWrapperArea;
    margin-top: 10px;
}

.adminUserArea1 {
    grid-area: area1;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.adminUserArea2 {
    grid-area: area2;

    margin-top: 20px;

    display: inline;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.adminUserArea3 {
    grid-area: area3;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.adminUserArea4 {
    grid-area: area4;

    margin-top: 20px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.adminUserArea5 {
    grid-area: area5;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.adminuserArea2 input {
    width: unset;
}

.adminUserArea4 input {
    width: unset;
}


/*******************************************************************************************************/
/* ADMIN System STYLES
/*******************************************************************************************************/
.systemsLogo {
    width: 25px;
    height: 25px;
    vertical-align: middle;
    border-radius: 22.5%;
}

.systemsForm {
    display: block;
    max-width: 250px !important;
}

.systemsFormHidden {
    display: none;
}

.systemsContainer {
    display: flex;
    width: 100%;
    gap: 10px 10px;

    margin-top: 10px;
}

.systemsArea1 {
    grid-area: area1;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10px;
}

.systemsArea2 {
    grid-area: area2;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10px;
}


.systemsArea3 {
    grid-area: area3;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flexShrink: 1;
}

.systemsArea3 table {
    font-size: unset !important;
    width: max-content;
}

.systemsArea3 table td {
    padding-right: 10px;
}

.systemsArea4 {
    grid-area: area4;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    padding: 10px;
}

/*******************************************************************************************************/
/* INSTALL STYLES
/*******************************************************************************************************/
.installSpinner {
    display: inline-block !important;
}

.installDoneCheckMark {
    color: var(--default-green-color)
}

.xMark{
    color: var(--default-red-color);
}

.installAdminUserCredentials {
    font-size: 20px;
    line-height: 25px;

    background-color: var(--background-color-3);
    padding: 10px;
}

.installContainer {
    background-image: var(--login-background-image);
    background-position: center;
    background-size: cover;
  
    display: flex;
    flex-direction: row;
    height: 100vh;
  }

  @keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.installModal {
    position: absolute;
    width: 100vw;
    height: 100vh;

    z-index: 1000;
    display: flex;
    flex-direction: column;

    backdrop-filter: blur(20px);
    top: 0px;
    left: 0px;

    animation: fadein .5s;
}

.installModalInnerInner::-webkit-scrollbar {
    display: none;
}

.installModalInner {
    position: absolute;
    width: 50%;
    height: 75%;

    top: 12.5%;
    left: 25%;
    padding: 20px; 

    z-index: 1000;
    display: flex;
    flex-direction: column;

    padding: 10px;
    border-radius: 10px;
    background: var(--background-color-2);
    box-shadow: var(--default-box-shadow);
    overflow: auto;

    align-items: center;
}

.installModalInner h2 {
    line-height: 40px !important;
}

.installModalInnerInner td {
    text-align: left;
    width: max-content;
    min-width: 150px;
}

.installModalInnerInner {
    width: 75%;

    justify-content: center;
    align-items: center;
    text-align: center;
}

@media (max-width: 900px) {
    .installModalInner {
        position: absolute;
        width: 90%;
        height: 75%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 20px;
        z-index: 1000;
        display: flex;
        flex-direction: column;
        padding: 10px;
        border-radius: 10px;
        background: var(--background-color-2);
        box-shadow: var(--default-box-shadow);
        overflow: auto;
        align-items: center;
    }
    
    .installModalInnerInner {
        width: 75%;
        justify-content: center;
        align-items: center;
        text-align: center;

        display: flex;
        flex-direction: column;
    }
}
/*******************************************************************************************************/
/* MODAL STYLES
/*******************************************************************************************************/
@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.modal {
    position: absolute;
    width: 100vw;
    height: 100vh;

    z-index: 1000;
    display: flex;
    flex-direction: row;

    backdrop-filter: blur(20px);
    top: 0px;
    left: 0px;

    animation: fadein .5s;
}

.modalInner {
    position: absolute;
    width: 50%;
    height: 75%;

    top: 12.5%;
    left: 25%;
    padding: 20px; 

    z-index: 1000;
    display: flex;
    flex-direction: row;
    
    padding: 10px;
    border-radius: 10px;
    background: var(--background-color-2);
    box-shadow: var(--default-box-shadow);
    overflow: auto;

    justify-content: center;
    align-items: center;
    text-align: center;
}


@media (max-width: 900px) {
    .modal {
        position: absolute;
        width: 100vw;
        height: 100vh;
    
        z-index: 1000;
        display: flex;
        flex-direction: row;
    
        backdrop-filter: blur(20px);
        top: 0px;
        left: 0px;
    
        animation: fadein .5s;
    }
    
    .modalInner {
        position: absolute;
        width: 90%;
        height: 75%;
        top: calc(50% - 75%/2);
        left: calc(50% - 90%/2);

        padding: 20px; 
    
        z-index: 1000;
        display: flex;
        flex-direction: row;
    
        padding: 10px;
        border-radius: 10px;
        background: var(--background-color-2);
        box-shadow: var(--default-box-shadow);
        overflow: auto;
    }
}