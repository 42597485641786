@media (prefers-color-scheme: light) {
    :root {
        --gradient-color-1: #E22A6F;
        --gradient-color-2: #FE6666;
        /* Optionale Color für Gradients. Mitte aus color-1 und color-2 */
        --gradient-color-15: #f24a69;

        --background-color-1: #fafafa;
        --background-color-2: #f5f5f5;
        --background-color-3: #f1f1f1;
        --background-color-1-55: #fafafa55;

        --background-color-1-AA: #fafafaAA;
        --background-color-1-DD: #fafafaDD;
        
        --background-color-2-55: #f5f5f555;
        --background-color-3-55: #f1f1f155;
        --background-color-3-AA: #f1f1f1AA;

        --text-color: #404040;
        --text-color-invert: #f3f4f6;
        --text-color-search: #f3f4f6;
        --text-color-disabled: #6b6b6b;
        --scrollbar-thumb-color: #080808;
        --login-input-border-color: #f3f4f6;
        --input-border-color: rgba(0,0,0,0.1);
        --placeholder-color: #080808;
        --svg-color: #040404;
        --default-box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
        --default-box-shadow-hover: 0px 10px 15px -3px rgba(0,0,0,0.2);
        --coloured-gradient: transparent linear-gradient(180deg, var(--gradient-color-1) 0%, var(--gradient-color-2) 100%) 0% 0% no-repeat padding-box;
        --button-coloured-text-color: #ffffff;
        --toggle-button-color: #040404;

        --default-red-color: #fd1111;
        --default-blue-color: #0d34c1;
        --default-green-color: #4bf624;
        --default-yellow-color: #fbe816;
        --default-black-color: #000000;
        --default-white-color: #ffffff;

        --login-background-image: url('./backgroundDark2.jpg');
        --login-logo-image: url("./media/logo_white.png");
        --logo-layout-image: url("./media/logo_dark.png");
        --layout-background-image: url("./media/bg_white.jpg");
        --layout-background-color: #f3f4f6;
        --layout-background-overlay: #fdfdfd75;
    }
}