@media (prefers-color-scheme: dark) {
    :root {
        --gradient-color-1: #E22A6F;
        --gradient-color-2: #FE6666;
        /* Optionale Color für Gradients. Mitte aus color-1 und color-2 */
        --gradient-color-15: #f24a69;
    
        --background-color-1: #090c10;
        --background-color-2: #0d1117;
        --background-color-3: #161b22;
        --background-color-1-55: #090c1055;

        --background-color-1-AA: #0f151bAA;
        --background-color-1-DD: #0f151bDD;

        --background-color-2-55: #0d111755;
        --background-color-3-55: #161b2255;
        --background-color-3-AA: #161b22AA;

        --text-color: #f3f4f6;
        --text-color-invert: #1d1d1d;
        --text-color-search: #f3f4f6;
        --text-color-disabled: #6b6b6b;
        --scrollbar-thumb-color: #080808;
        --login-input-border-color: #f3f4f6;
        --input-border-color: #f3f4f6;
        --placeholder-color: #f3f4f6;
        --svg-color: #f3f4f6;
        --default-box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
        --default-box-shadow-hover: 0px 10px 15px -3px rgba(0,0,0,0.9);
        --coloured-gradient: transparent linear-gradient(180deg, var(--gradient-color-1) 0%, var(--gradient-color-2) 100%) 0% 0% no-repeat padding-box;
        --button-coloured-text-color: #ffffff;
        --toggle-button-color: #f3f4f6;

        --default-red-color: #fd1111;
        --default-blue-color: #0d34c1;
        --default-green-color: #4bf624;
        --default-yellow-color: #fbe816;
        --default-black-color: #000000;
        --default-white-color: #ffffff;

        --login-background-image: url('./backgroundDark2.jpg');
        --login-logo-image: url("./media/logo_white.png");
        --logo-layout-image: url("./media/logo_white.png");
        --layout-background-image: url("./media/bg_dark5.jpg");
        --layout-background-color: #16151a;
        --layout-background-overlay: #00000075;
    }
}